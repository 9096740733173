import Axios from './http';
import Selection from './Selection';

import { decodeHtmlEntities } from '@/utils/Utils';
export default class Exhib {
  /**
   * @description 查询邀請函列表
   * @return {HttpResponse} result
   */
  static async list(params) {
    return Axios('/exhib/find', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async mine(params) {
    return Axios('/exhib/mine', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async resorter(params) {
    return Axios('/exhib/resorter', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async profile(params) {
    return Axios(`/exhib/detail`, {
      method: 'post',
      responseType: 'json',
      data: params,
    }).then((res) => {
      if (res.data) {
        res.data.poster_work = res.data.poster_work
          ? res.data.poster_work.toString()
          : null;
        res.data.banner_work = res.data.banner_work
          ? res.data.banner_work.toString()
          : null;

        res.data.excerpt = decodeHtmlEntities(res.data.excerpt);
      }

      return res;
    });
  }

  static async simple(params) {
    return Axios(`/exhib/simple`, {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async blueprint(params) {
    return Axios(`/exhib/blueprint`, {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async items(params) {
    return Selection.list(params);
  }

  static async create(params) {
    return Axios(`/exhib/new`, {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async del(params) {
    return Axios(`/exhib/remove`, {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }

  static async publish(params) {
    return Axios(`/exhib/submit`, {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async update(params) {
    return Axios(`/exhib/update`, {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
}
