import Axios from './http';
import { transformProps, decodeHtmlEntities } from '@/utils/Utils';
import { uploadUrl } from '@/utils/Utils';
import _ from 'lodash';

export default class Selection {
  /**
   * @description 查询邀請函列表
   * @return {HttpResponse} result
   */
  static async list(params) {
    let url = '';
    return Axios('/exhib/items', {
      method: 'post',
      responseType: 'json',
      data: params,
    }).then((res) => {
      if (res.data?.items) {
        res.data.items = res.data.items.map((x) => {
          return {
            title: x.title,
            excerpt: x.excerpt,
            pic: x.pic || x.cover,
            cover: x.cover || x.pic,
            model_big: x.file1,
            model: x.file2,
            color: x.color,
            videouri: x.videouri,
            link2: x.link2,
            extra: JSON.parse(x.extra),
            ...transformProps(x),
          };
        });
      }
      return res;
    });
  }

  static async find(params) {
    return Axios(`/chip/detail`, {
      method: 'post',
      responseType: 'json',
      data: params,
    }).then((res) => {
      if (res.data) {
        const x = res.data;
        // if (x.cover) {
        //   x.cover = x.cover.replace(/\.png$/, '.webp?png');
        // }
        // if (x.pic) {
        //   x.pic = x.pic.replace(/\.png$/, '.webp?png');
        // }
        // console.log(x.pic);
        res.data = {
          title: x.title,
          excerpt: decodeHtmlEntities(x.excerpt),
          pic: x.pic || x.cover,
          cover: x.cover || x.pic,
          color: x.color || '#333333',
          year: x.year,
          size: x.size,
          creator: x.creator || x.author,
          videouri: x.videouri,
          link2: x.link2,
          extra: JSON.parse(x.extra),
          ...transformProps(x),
        };
      }

      return res;
    });
  }
  static async insert(params) {
    // params.blank = params.blank || 'no';
    // params.others = params.other || 'no';
    return Axios(`/chip/new`, {
      method: 'post',
      responseType: 'json',
      data: params,
    }).then((res) => {
      if (res.data) {
        const x = res.data;
        res.data = {
          title: x.title,
          excerpt: x.excerpt,
          pic: x.pic || x.cover,
          cover: x.cover || x.pic,
          model_big: x.file1,
          model: x.file2,
          color: x.color,
          videouri: x.videouri,
          link2: x.link2,
          extra: JSON.parse(x.extra),
          ...transformProps(x),
        };
      }

      return res;
    });
  }
  static async delete(params) {
    return Axios(`/chip/disabled`, {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async clone(params) {
    return Axios(`/chip/clone`, {
      method: 'post',
      responseType: 'json',
      data: params,
    }).then((res) => {
      if (res.data?.items) {
        res.data.items = res.data.items.map((x) => {
          return {
            title: x.title,
            excerpt: x.excerpt,
            pic: x.pic || x.cover,
            cover: x.cover || x.pic,
            model_big: x.file1,
            model: x.file2,
            color: x.color,
            videouri: x.videouri,
            link2: x.link2,
            extra: JSON.parse(x.extra),
            ...transformProps(x),
          };
        });
      }
      console.log(res);
      return res;
    });
  }

  static async update(params) {
    return Axios(`/chip/update`, {
      method: 'post',
      responseType: 'json',
      data: params,
    }).then((res) => {
      if (res.data) {
        const x = res.data;
        res.data = {
          title: x.title,
          pic: x.pic || x.cover,
          cover: x.cover || x.pic,
          model_big: x.file1,
          model: x.file2,
          color: x.color,
          excerpt: x.excerpt,
          extra: JSON.parse(x.extra),
          ...transformProps(x),
        };
      }
      return res;
    });
  }

  static async sort(params) {
    return Axios(`/exhib/resorter`, {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
}
